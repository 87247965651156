import React from 'react';
import MapComponent from '../Map/Map';

const Home = () => {
    return (<div>
        {/*<WhatWeDo/>*/}
        <MapComponent/>
        {/*<HeroSection/>*/}
    </div>);
}

export default Home;
