import React, {useEffect, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import Slider from "rc-slider";
import L from 'leaflet';
import 'rc-slider/assets/index.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import './Map.css'
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../firebase-config";

// Correct the default marker icon path issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Custom blue icon for clinical trials
const CTIcon = new L.Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/14035/14035451.png',
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [40, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

// Custom red icon for patient organizations
const POIcon = new L.Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/9131/9131546.png',
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [40, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const CompanyIcon = new L.Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/727/727606.png',
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [40, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const MapComponent = () => {
    const [locations, setLocations] = useState([]);
    const [clinicalTrials, setClinicalTrials] = useState([]);
    const [patientOrgs, setPatientOrgs] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]); // Selected services
    const [dropdownOpen, setDropdownOpen] = useState(false); // Controls dropdown visibility
    const [enableAgeFilter, setEnableAgeFilter] = useState(false);

    const handleAgeFilterToggle = () => {
        setEnableAgeFilter(prev => !prev);
    };

    const handleResetFilters = () => {
        setSelectedServices([]);
        setDropdownOpen(false);
        setEnableAgeFilter(false);
        setShowClinicalTrials(true);
        setShowPatientOrganizations(true);
        setShowCompanies(true);
        setEnablePatientCountFilter(false);
        setPatientCountRange([1, 1000]);
        setShowOnlyRecruiting(false);
        setShowPatientOrganizations(false)
        setShowCompanies(false)
        setFilter({
            ambulatory: false,
            corticosteroidUse: false,
            ambulatoryStatus: 'ambulant',
            enableAmbulatoryFilter: false,
            ageRange: [0, 30],
        });
    };

    const servicesList = [{value: 'genetic_counseling', label: 'Genetic counseling'}, {
        value: 'occupational_therapy', label: 'Occupational therapy (OT)'
    }, {value: 'orthopedics', label: 'Orthopedics'}, {
        value: 'orthotics', label: 'Orthotics'
    }, {value: 'palliative_care', label: 'Palliative care'}, {
        value: 'physical_therapy', label: 'Physical therapy'
    }, {value: 'psychology', label: 'Psychology'}, {
        value: 'social_work', label: 'Social work'
    }, {value: 'wheelchair_specialist', label: 'Wheelchair specialist'}, {
        value: 'counseling', label: 'Counseling'
    }, {value: 'nutrition', label: 'Nutrition'}, {
        value: 'speech_therapy', label: 'Speech therapy'
    }, {value: 'clinic_coordination', label: 'Clinic coordination'}, {
        value: 'dme', label: 'Durable medical equipment (DME)'
    }];

    const servicesMap = Object.fromEntries(servicesList.map(service => [service.value, service.label]));


// Handle service selection
    const handleServiceSelection = (e) => {
        const value = e.target.value;
        setSelectedServices((prev) => prev.includes(value) ? prev.filter(service => service !== value) : [...prev, value]);
    };
    const [showClinicalTrials, setShowClinicalTrials] = useState(true);
    const [showPatientOrganizations, setShowPatientOrganizations] = useState(false);
    const [showCompanies, setShowCompanies] = useState(false);
    const [enablePatientCountFilter, setEnablePatientCountFilter] = useState(false);
    const [patientCountRange, setPatientCountRange] = useState([1, 1000]);
    const [showOnlyRecruiting, setShowOnlyRecruiting] = useState(false);
    const [filter, setFilter] = useState({
        ambulatory: false,
        corticosteroidUse: false,
        ambulatoryStatus: 'ambulant',
        enableAmbulatoryFilter: false,
        ageRange: [0, 30],
    });

    const handlePatientCountToggle = () => {
        setEnablePatientCountFilter(prev => !prev);
    };

    const handlePatientCountRangeChange = (value) => {
        setPatientCountRange(value);
    };

    const handleToggleChange = (event) => {
        const {name, checked} = event.target;
        if (name === 'showClinicalTrials') {
            setShowClinicalTrials(checked);
        } else if (name === 'showPatientOrganizations') {
            setShowPatientOrganizations(checked);
        } else if (name === 'showOnlyRecruiting') {
            setShowOnlyRecruiting(checked);
        } else if (name === 'showCompanies') {
            setShowCompanies(checked);
        }
    };

    const handleSliderChange = (value) => {
        setFilter(prev => ({...prev, ageRange: value}));
    };

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        setFilter(prev => ({...prev, [name]: checked}));
    };

    useEffect(() => {
        fetch('https://repository.duchennedatafoundation.org/api/3/action/datastore_search?id=DMD%20and%20BMD%20Clinical%20Trials&limit=500&fields=NCTID%2CTitle%2CSex%2CLast%20Update%20Submit%20Date%2CMentionsCorticosteroidUse%2CMinimum%20Age%20%28Years%29%2CMaximum%20Age%20%28Years%29%2CMentions%20Ambulant%20Participation%2COverall%20Status%2CMentions%20Non%20Ambulant%20Participation')
            .then(response => response.json())
            .then(data => setClinicalTrials(data.result.records))
            .catch(error => console.error('Error fetching clinical trials data:', error));
    }, []);

    useEffect(() => {
        fetch('https://repository.duchennedatafoundation.org/api/3/action/datastore_search?id=DMD%20and%20BMD%20GeoLocation&limit=5000')
            .then(response => response.json())
            .then(data => setLocations(data.result.records))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    // Fetch patient organizations from Firestore
    useEffect(() => {
        const fetchPatientOrganizations = async () => {
            const q = query(collection(db, 'users'), where('role', '==', 'patient organization'));
            const querySnapshot = await getDocs(q);
            let orgs = querySnapshot.docs.map(doc => ({
                id: doc.id, ...doc.data()
            }));

            if (enablePatientCountFilter) {
                orgs = orgs.filter(org => org.numberOfPatients >= patientCountRange[0] && org.numberOfPatients <= patientCountRange[1]);
            }

            if (selectedServices.length > 0) {
                orgs = orgs.filter(org => org.services && org.services.some(service => selectedServices.includes(service)));
            }
            setPatientOrgs(orgs);
        };

        const fetchCompanies = async () => {
            const q = query(collection(db, 'users'), where('role', '==', 'company'));
            const querySnapshot = await getDocs(q);
            const companies = querySnapshot.docs.map(doc => ({
                id: doc.id, ...doc.data()
            }));
            setCompanies(companies);
        };

        fetchCompanies();
        fetchPatientOrganizations();
    }, [enablePatientCountFilter, patientCountRange, selectedServices]);

    const filteredLocations = locations.filter(location => {
        const trial = clinicalTrials.find(trial => trial.NCTID === location.nctid);
        // If no matching trial is found, exclude the location
        if (!trial) return false;

        // Start with a flag that assumes inclusion
        let includeLocation = true;

        console.log(trial)
        // Apply Ambulatory Filtering if enabled
        if (filter.enableAmbulatoryFilter) {
            if (filter.ambulatoryStatus === 'ambulant' && trial["Mentions Ambulant Participation"] !== true) {
                includeLocation = false;
            }
            if (filter.ambulatoryStatus === 'non-ambulant' && trial["Mentions Non Ambulant Participation"] !== true) {
                includeLocation = false;
            }
        }

        // Check corticosteroid use filter
        if (filter.corticosteroidUse && !trial.MentionsCorticosteroidUse) {
            includeLocation = false;
        }

        // Check the age filter
        if (enableAgeFilter) {
            const trialMinAge = parseInt(trial["Minimum Age (Years)"], 10);
            const trialMaxAge = parseInt(trial["Maximum Age (Years)"], 10) || 100;
            const [minAge, maxAge] = filter.ageRange;
            if (trialMinAge < minAge || trialMaxAge > maxAge) {
                includeLocation = false;
            }
        }

        // Apply Recruiting filter
        if (showOnlyRecruiting && trial["Overall Status"] !== "RECRUITING") {
            includeLocation = false;
        }

        // Return the final inclusion flag
        return includeLocation;
    });

    return (<div className="map-and-filter-container">
        <MapContainer center={[0, 0]} zoom={3} minZoom={3}
                      style={{height: '1000px', width: '80%', flexGrow: 1, flexBasis: '80%'}}>
            <TileLayer
                url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {showClinicalTrials && (<MarkerClusterGroup>
                {filteredLocations.map((location, index) => {
                    // Find the matching trial by nctid
                    const trial = clinicalTrials.find(trial => trial.NCTID === location.nctid);
                    return (<Marker key={index} icon={CTIcon} position={[location.Latitude, location.Longitude]}>
                        <Popup>
                            <div className="popup-content">
                                <p><strong>Title:</strong> {trial?.Title || 'N/A'}</p>
                                <p><strong>Trial Status:</strong> {trial?.['Overall Status'] || 'N/A'}</p>
                                <p><strong>Sex:</strong> {trial?.Sex || 'N/A'}</p>
                                <p><strong>Minimum Age:</strong> {trial?.['Minimum Age (Years)'] || 'N/A'}</p>
                                <p><strong>Maximum Age:</strong> {trial?.['Maximum Age (Years)'] || 'N/A'}</p>
                                <p><strong>Location Status:</strong> {location?.['Location Status'] || 'N/A'}
                                </p>
                                <p><strong>Facility Name:</strong> {location?.['Facility Name'] || 'N/A'}</p>
                                <p><strong>Facility City:</strong> {location?.['Facility City'] || 'N/A'}</p>
                                <p><strong>Facility State:</strong> {location?.['Facility State'] || 'N/A'}</p>
                                <p><strong>Facility Country:</strong> {location?.['Facility Country'] || 'N/A'}
                                </p>
                                {location?.Contacts && location.Contacts.split('\n').map((contact, idx) => {
                                    const [name, role, phone, email] = contact
                                        .split(', ')
                                        .map(info => info.split(': ')[1] || 'N/A');
                                    return (<div key={idx} style={{marginBottom: '10px'}}>
                                        <p><strong>Contact Name:</strong> {name}</p>
                                        <p><strong>Role:</strong> {role}</p>
                                        <p><strong>Phone:</strong> {phone}</p>
                                        <p><strong>Email:</strong> {email}</p>
                                    </div>);
                                })}
                                <p>
                                    <strong>Find more:</strong>{' '}
                                    <a
                                        href={`https://clinicaltrials.gov/study/${location.nctid}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {location.nctid}
                                    </a>
                                </p>
                                <p><strong>Last Update:</strong> {trial?.['Last Update Submit Date'] || 'N/A'}
                                </p>
                            </div>
                        </Popup>
                    </Marker>);
                })}
            </MarkerClusterGroup>)}
            {showPatientOrganizations && patientOrgs.map((org, index) => {
                if (org.location) {
                    const {latitude, longitude} = org.location;
                    return (<Marker key={index} icon={POIcon} position={[latitude, longitude]}>
                        <Popup>
                            <div>
                                <p><strong>Patient Organization:</strong> {org.name}</p>
                                <p><strong>Address:</strong> {org.address}</p>
                                <p><strong>Email:</strong> {org.email}</p>
                                <p><strong>Contact Name:</strong> {org.contactName}</p>
                                <p><strong>Phone:</strong> {org.contactPhone}</p>
                                {org.website && (<p><strong>Website:</strong>
                                    <a href={org.website} target="_blank" rel="noopener noreferrer">
                                        {org.website}
                                    </a>
                                </p>)}
                                {org.numberOfPatients > 0 && (
                                    <p><strong>Number of Patients:</strong> {org.numberOfPatients}</p>)}
                                {org.services && org.services.length > 0 && (
                                    <p><strong>Services Provided:</strong> {org.services
                                        .map(service => servicesMap[service] || service) // Map to readable names
                                        .join(', ') // Join into a string
                                    }</p>)}
                            </div>
                        </Popup>
                    </Marker>);
                }
                return null;
            })}
            {showCompanies && companies.map((org, index) => {
                if (org.location) {
                    const {latitude, longitude} = org.location;
                    return (<Marker key={index} icon={CompanyIcon} position={[latitude, longitude]}>
                        <Popup>
                            <div>
                                <p><strong>Company:</strong> {org.name}</p>
                                <p><strong>Address:</strong> {org.address}</p>
                                <p><strong>Email:</strong> {org.email}</p>
                                <p><strong>Contact Name:</strong> {org.contactName}</p>
                                <p><strong>Phone:</strong> {org.contactPhone}</p>
                                {org.website && (<p><strong>Website:</strong>
                                    <a href={org.website} target="_blank" rel="noopener noreferrer">
                                        {org.website}
                                    </a>
                                </p>)}
                            </div>
                        </Popup>
                    </Marker>);
                }
                return null;
            })}
        </MapContainer>
        <div className="filter-sidebar">
            <div className="filter-title">Clinical Trials Sites<img
                src="https://cdn-icons-png.flaticon.com/512/14035/14035451.png"
                alt="Clinical Trials Marker"
                style={{width: '20px', height: '20px', marginLeft: '8px'}}
            /></div>
            <form>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="showClinicalTrials">
                            <input
                                type="checkbox"
                                id="showClinicalTrials"
                                name="showClinicalTrials"
                                checked={showClinicalTrials}
                                onChange={handleToggleChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="showClinicalTrials">Show Clinical Trials Sites</label>
                    </div>
                </div>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="enableAmbulatoryFilter">
                            <input
                                type="checkbox"
                                id="enableAmbulatoryFilter"
                                name="enableAmbulatoryFilter"
                                checked={filter.enableAmbulatoryFilter}
                                onChange={handleCheckboxChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="enableAmbulatoryFilter">Enable Ambulatory Filtering</label>
                    </div>
                </div>
                {filter.enableAmbulatoryFilter && (<div className="ambulatory-toggle">
                    <button
                        type="button"
                        className={`ambulatory-button ${filter.ambulatoryStatus === 'ambulant' ? 'selected' : ''}`}
                        onClick={() => setFilter(prev => ({
                            ...prev, enableAmbulatoryFilter: true, ambulatoryStatus: 'ambulant'
                        }))}
                    >
                        Ambulant
                    </button>
                    <button
                        type="button"
                        className={`ambulatory-button ${filter.ambulatoryStatus === 'non-ambulant' ? 'selected' : ''}`}
                        onClick={() => setFilter(prev => ({
                            ...prev, enableAmbulatoryFilter: true, ambulatoryStatus: 'non-ambulant'
                        }))}
                    >
                        Non-Ambulant
                    </button>
                </div>)}
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="corticosteroidUse">
                            <input
                                type="checkbox"
                                id="corticosteroidUse"
                                name="corticosteroidUse"
                                checked={filter.corticosteroidUse}
                                onChange={handleCheckboxChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="corticosteroidUse">Using Corticosteroids</label>
                    </div>
                </div>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="showOnlyRecruiting">
                            <input
                                type="checkbox"
                                id="showOnlyRecruiting"
                                name="showOnlyRecruiting"
                                checked={showOnlyRecruiting}
                                onChange={handleToggleChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="showOnlyRecruiting">Show Only Recruiting Trials</label>
                    </div>
                </div>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="enableAgeFilter">
                            <input
                                type="checkbox"
                                id="enableAgeFilter"
                                checked={enableAgeFilter}
                                onChange={handleAgeFilterToggle}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="enableAgeFilter">Enable Age Participation Filter</label>
                    </div>
                </div>
                {enableAgeFilter && (<div className="slider-container">
                    <label>Age Participation Range: {filter.ageRange[0]} - {filter.ageRange[1]}</label>
                    <Slider
                        range
                        min={0}
                        max={80}
                        defaultValue={filter.ageRange}
                        onChange={handleSliderChange}
                    />
                </div>)}
                <div style={{borderTop: '1px solid #ccc', margin: '20px 0'}}></div>
                <div className="filter-title">Patient Organizations<img
                    src="https://cdn-icons-png.flaticon.com/512/9131/9131546.png"
                    alt="Clinical Trials Marker"
                    style={{width: '20px', height: '20px', marginLeft: '8px'}}
                /></div>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="showPatientOrganizations">
                            <input
                                type="checkbox"
                                id="showPatientOrganizations"
                                name="showPatientOrganizations"
                                checked={showPatientOrganizations}
                                onChange={handleToggleChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="showPatientOrganizations">Show Patient Organizations</label>
                    </div>
                </div>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="enablePatientCountFilter">
                            <input
                                type="checkbox"
                                id="enablePatientCountFilter"
                                checked={enablePatientCountFilter}
                                onChange={handlePatientCountToggle}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="enablePatientCountFilter">Enable Number of Patients Filter</label>
                    </div>
                </div>
                {enablePatientCountFilter && (<div className="slider-container">
                    <label>Number of Patients: {patientCountRange[0]} - {patientCountRange[1]}</label>
                    <Slider
                        range
                        min={1}
                        max={1000}
                        defaultValue={patientCountRange}
                        onChange={handlePatientCountRangeChange}
                    />
                </div>)}
                <div className="form-group dropdown">
                    <button
                        type="button"
                        className="dropdown-button"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                        Select Services ▼
                    </button>

                    {dropdownOpen && (<div className="dropdown-content">
                        {servicesList.map((service) => (<label key={service.value} className="dropdown-item">
                            <span className="dropdown-text">{service.label}</span>
                            <input
                                type="checkbox"
                                value={service.value}
                                checked={selectedServices.includes(service.value)}
                                onChange={handleServiceSelection}
                            />
                        </label>))}
                    </div>)}
                </div>
                <div style={{borderTop: '1px solid #ccc', margin: '20px 0'}}></div>
                <div className="filter-title">Companies<img
                    src="https://cdn-icons-png.flaticon.com/512/727/727606.png"
                    alt="Clinical Trials Marker"
                    style={{width: '20px', height: '20px', marginLeft: '8px'}}
                /></div>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="showCompanies">
                            <input
                                type="checkbox"
                                id="showCompanies"
                                name="showCompanies"
                                checked={showCompanies}
                                onChange={handleToggleChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="showCompanies">Show Companies</label>
                    </div>
                </div>
                <button className="reset-filters-button" onClick={handleResetFilters}>
                    Reset Filters
                </button>
            </form>
        </div>
    </div>);
};

export default MapComponent;
